<template>
    <div>
        <div class="text-center" style="margin-top: 10px;">
            <div style="font-size: 16px;"><strong>Activos/Terminados</strong></div>
        </div>        
        <div style="width: 100%; height: 300px;" ref="activeFinishBars">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5index from "@amcharts/amcharts5/index";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import ws from '../../services/dashboard';
import store from '../../store'

export default {
    name: 'ReportActiveFinishBars',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return ""
            }
        },
        range: {
            type: String,
            default: '20240101-20240131'
        }              
    },
    components : {
	},
    data: function () {
		return {
            data: {},
            items: {},
            root: "",
            chart: "",
            xAxis:"",
            yAxis:"",
            wheelX: false,
            wheelY: false,
            data_response:"",
            legend:"",
        }
    },
    computed: {
        
    },
    mounted: async function(){
    },
    methods: {
        makeSeries(field, name, color,root, data_response) {
            let series = this.chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                wheelX: false,
                wheelY: false,
                valueYField: field,
                categoryXField: "direction",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{valueY} Reportes"
                })
            }));

            series.columns.template.setAll({
                height: am5.p100,
                strokeOpacity: 0,
                fill: am5.color(color)
            });

            series.bullets.push(function(root, series, dataItem) {
                return am5.Bullet.new(root, {
                    locationY: 1,  // Ubicación en la parte superior de la barra
                    sprite: am5.Label.new(root, {
                        text: "{valueY}", // 🔥 Aquí se define el texto dinámico
                        fill: am5.color("#000000"), // Color del texto
                        centerY: am5.p100,
                        centerX: am5.p50,
                        fontSize: 14,
                        populateText: true

                    })
                });
            });


            // series.bullets.push(function () {
            //     return am5.Bullet.new(root, {
            //     locationY: 1,
            //     // locationY: 0.5,
            //         // sprite: am5.Label.new(root, {
            //         //     centerY: am5.p50,
            //         //     text: "{valueX}",
            //         //     populateText: true
            //         // })
            //         sprite: am5.Label.new(root, {
            //             text: "{valueY}",
            //             fill: am5.color("#000000"), // Color del texto
            //             centerY: am5.p0,
            //             centerX: am5.p50,
            //             fontWeight: "bold",
            //             fontSize: 14 // Tamaño de texto del total
            //         })
            //     });
            // });

            series.data.setAll(data_response);
            series.appear();

            return series;
        },
        async loadGraph(data){
            if (this.root) {
                this.root.dispose();
            }

            this.root = am5.Root.new(this.$refs.activeFinishBars);

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);
            
            this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: false,
                wheelY: false,
                paddingLeft:0,
                layout: this.root.verticalLayout
            }));

            this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "direction",
                renderer: am5xy.AxisRendererX.new(this.root, {
                    inversed: false,
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                    minorGridEnabled: false,
                    minGridDistance: 20 // Ajusta este valor según sea necesario
                })
            }));
            this.xAxis.get("renderer").grid.template.set("forceHidden", true);
            this.xAxis.data.setAll(data.data);

            this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererY.new(this.root, {
                    strokeOpacity: 0.1,
                    minGridDistance: 40, 
                    // minorGridEnabled: false,
                }),
                min: 0
            }));

            //Quitar labels del eje de las Xs
            this.yAxis.get("renderer").labels.template.set("forceHidden", true);
            this.yAxis.get("renderer").grid.template.set("forceHidden", true);

            this.makeSeries("active", data.label_1, data.color_1,this.root, data.data);
            this.makeSeries("finish", data.label_2, data.color_2,this.root, data.data);

            this.legend = this.chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50
            }));

            this.xAxis.get("renderer").labels.template.setAll({
                forceHidden: false, // Mostrar todas las etiquetas
                visible: true       // Garantizar visibilidad
            });

            this.xAxis.get("renderer").labels.template.setAll({
                centerY: am5.p50,
                centerX: am5.p50
            });

            this.legend.data.setAll(this.chart.series.values);

            let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
                behavior: "zoomX"
            }));
            cursor.lineY.set("forceHidden", true);
            cursor.lineX.set("forceHidden", true);

            this.chart.appear(1000, 100);
         
        },
        async setData(params){
            let response = await ws.getActiveFinishGraph(params);

            if(response.type == "success"){
                this.data = response.data;
                this.loadGraph(this.data);
            }
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
