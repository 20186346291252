<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px; margin-top: 10px;"><strong>Direccion/Reportes</strong></div>
        </div>        
        <div style="width: 100%; height: 300px;" ref="directionPie">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import ws from '../../services/dashboard';

export default {
    name: 'ReportDirectionPie',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default: ''
        }
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        loadGraph: async function(data_response){
            if (this.root) {
                this.root.dispose();
            }
            this.root = am5.Root.new(this.$refs.directionPie);        
            this.root.setThemes([am5themes_Animated.new(this.root)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root.container.children.push(
                // Se inicializa el tipo de grafica
                am5percent.PieChart.new(this.root, {
                    layout: this.root.verticalLayout,
                })
            );

            // Creamos la serie (cada serie seria cada columna)
            let slicePie = chart.series.push(am5percent.PieSeries.new(this.root, {
                    name: "slicePie",
                    valueField: "total",
                    categoryField: "direction",
                    legendLabelText: "{category}: {value} ({valuePercentTotal.formatNumber('#.')}%)"
                }));

            slicePie.labels.template.setAll({
                text: "{category}: [bold]{value}[/] ({valuePercentTotal.formatNumber('#.')}%)", 
                oversizedBehavior: "truncate", // Controlar si el texto es muy largo
                fill: am5.color(0x000000)     // Color del texto (negro en este caso)
            });

            slicePie.slices.template.set("tooltipText", "{category}: {valuePercentTotal.formatNumber('#.')}%");

            //Si se tiene que repetir
            slicePie.data.setAll(data_response);
            //relleno
            slicePie.slices.template.adapters.add("fill", function(fill, target) {
                let context = target.dataItem.dataContext;
                return am5.color(context.color);
            });
            //Orillas
            slicePie.slices.template.adapters.add("stroke", function(stroke, target) {
                let context = target.dataItem.dataContext;
                return am5.color(context.color);                                
            });

            slicePie.data.setAll(data_response);

            slicePie.appear(1000, 100);
        },
        async setData(params){
            let response = await ws.getDirectionTicketsGraph(params);

            if(response.type == "success"){
                this.data = response.data;
                this.loadGraph(this.data);
            }
        },
    },
  	mounted: async function(){
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
