<template>
  <div>
    <CRow>
      <CCol sm="12">
          <CCard style="height: 70px;">            
            <CRow>
              <CCol sm="6">
                <div style="margin: 15px 0px 0px 50px">
                  <h2 style="margin: 0px;"><strong>{{account.name}}</strong></h2>
                </div>                
              </CCol>
              <CCol sm="6" class="d-flex justify-content-end align-items-center">                
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                    title-position="left"
                    :columns="2"
                    style="width: 500px; font-size: 12px !important; margin-top: 15px; margin-right: 10px;"
                  />                    
                  <CSelect
                    :value.sync="direction_id"
                    :options="directionsOptions"
                    invalidFeedback="Este campo es requerido."
                    style="width: 250px; font-size: 12px !important; margin: 15px 10px 0px 0px;"
                    @change="changeFilters"
                    v-if="user.direction_id == 0"
                  />
              </CCol>                        
            </CRow>
          </CCard>
      </CCol>
    </CRow>
    <CRow>           
      <CCol md="3">
        <CWidgetSimple style="height: 100px;" header="Total Reportes" :text="String(metrics.tickets_total)"></CWidgetSimple>
      </CCol>     
      <CCol md="3">
        <CWidgetSimple style="height: 100px;" header="Porcentaje de Cumplimiento" :text="String(metrics.fulfillment_percent)"></CWidgetSimple>
      </CCol>     
      <CCol md="3">
        <CWidgetProgress
          style="height: 100px;"
          :header="String(metrics.active)"
          text="Reportes Activos"
          color="danger"
          :value="Number(metrics.active_percent)"
        >
        </CWidgetProgress>
      </CCol>  
      <CCol md="3">
        <CWidgetProgress
          style="height: 100px;"
          :header="String(metrics.finish)"
          text="Reportes Terminados"
          color="success"
          :value="Number(metrics.finish_percent)"
        >
        </CWidgetProgress>
      </CCol>   
    </CRow>    
    <CRow>           
      <CCol md="6">
        <CCard style="height: 350px;">
          <ReportActiveFinishBars ref="reportActiveFinishBars"></ReportActiveFinishBars>
        </CCard>
      </CCol>     
      <CCol md="6">
        <CCard style="height: 350px;">
          <template v-if="direction_id == 0">
            <ReportDirectionPie ref="reportDirectionPie"></ReportDirectionPie>
          </template>
          <template v-else="direction_id > 0">
            <FulfillmentGauge ref="fulfillmentGauge"></FulfillmentGauge>
          </template>          
        </CCard>
      </CCol>        
    </CRow>    
    <CRow>           
      <CCol md="6">
        <CCard style="height: 350px;">
          <StatusReportPie ref="statusReportPie"></StatusReportPie>
        </CCard>
      </CCol>     
      <CCol md="6">
        <CCard style="height: 350px;">
          <ReportDaysDelayBars ref="reportDaysDelayBars"></ReportDaysDelayBars>
        </CCard>
      </CCol>        
    </CRow>    
  </div>
</template>

<script>
import ReportActiveFinishBars from '../../components/graphs/ReportActiveFinishBars.vue';
import ReportDirectionPie from '../../components/graphs/ReportDirectionPie.vue';
import StatusReportPie from '../../components/graphs/StatusReportPie.vue';
import ReportDaysDelayBars from '../../components/graphs/ReportDaysDelayBars.vue';
import FulfillmentGauge from '../../components/graphs/FulfillmentGauge.vue';
import store from '../../store'
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import ws from '../../services/dashboard';
import ws_tickets from '../../services/tickets';

export default {
  name: 'Welcome',
  components: { 
    'v-date-picker': DatePicker,
    ReportActiveFinishBars,
    ReportDirectionPie,
    StatusReportPie,
    ReportDaysDelayBars,
    FulfillmentGauge
  },
  data: function () {
		return {
      direction_id: '',
      directions: [],
      directionsOptions: [],
      range: {
        start: new moment().startOf('year').toDate(),
        end: new moment().endOf("year").toDate(),
      },
      user: {direction_id: 0},
      account: {name: ""},
      metrics:{
        finish : "0",
        finish_percent : "0",
        active: "0",
        active_percent : "0",
        tickets_total: "0",
        fulfillment_percent: "0",
        fulfillment: "0",
      },
    }
  },
  mounted: async function() {
    this.loading();

    this.user = await JSON.parse(localStorage.getItem("user"));
    this.account = await JSON.parse(localStorage.getItem("account"));

    if(this.user.direction_id == 0){
      let response = await ws_tickets.getDirections();

      if(response.type == "success"){
        this.directions = response.data;

        this.directionsOptions = await this.$parseSelectOptionsOrdered(this.directions, "id", "name", [{"label": "Todas","value":""}]);
      }
    }
    this.getMetrics();
    this.getGraphs();

    this.loaded();
  },
  methods: {
    changeFilters: async function(event){
      this.loading();
        await this.getMetrics();
        await this.getGraphs();
      this.loaded();
    },
    getGraphs: async function(){ 
      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      if(this.user.direction_id > 0){
        this.direction_id = this.user.direction_id;
      }

      let params = {
        "date" : dates,
        "direction_id" : this.direction_id,
      };
      await this.$refs.reportActiveFinishBars.setData(params);      
      await this.$refs.statusReportPie.setData(params);
      await this.$refs.reportDaysDelayBars.setData(params);

      if(this.direction_id > 0){
        await this.$refs.fulfillmentGauge.loadGraph(this.metrics.fulfillment);        
      }else{
        await this.$refs.reportDirectionPie.setData(params);
      }
    },
    getMetrics: async function(){
      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      if(this.user.direction_id > 0){
        this.direction_id = this.user.direction_id;
      }

      let params = {
        "date" : dates,
        "direction_id" : this.direction_id,
      };

      let response = await ws.getMetrics(params);
      if(response.type == "success"){
        this.metrics = response.data;
      }
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  }
}
</script>
